import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import apiClient from "../../utils/axios";
import ReactPaginate from "react-paginate";
import CustomPagination from "../../reusables/Pagination";
import Button from '../../reusables/Button'


const ListPatients = () => {
	const [originaList, setOriginalList] = useState();
	const [listPatients, setListPatients] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState("");
	const [totalCount, setTotalCount] = useState(0);
	const [patient, setPatient] = useState({});
	const [openModal, setOpenModal] = useState(false);
	const [pageNo, setPageNo] = useState(1);
	const [limit, setLimit] = useState(10);
	const [isSuccess, setIsSuccess] = useState(false)
	const [successMessage, setSuccessMessage] = useState(false)
	const modalRef = useRef(null);

	
	const deleteModaHandler = (data) => {
		setOpenModal(true)
		setPatient(data)	
	}
	const closeModal = () => {
		setOpenModal(false)
		setPatient({})	
	}

	//api to remove patient
	const removePatientHandler = async (id) =>{      
      
      try {
        const response = await apiClient.post('patients/delete',{patient_id:id})
		  if (response.status === 200) {
			
          setOpenModal(false)
          setIsSuccess(true)
          setSuccessMessage("Patient deleted successfully")
              
          setTimeout(() => {
            setSuccessMessage("")
          }, 3000);
  
          
          fetchListPatients(pageNo)

        }
      } catch (error) {
        console.log(error)
      }
	}
	
	const handleSetLimit = (data) => {
		setLimit(data)
	}

	const handlePageClick = (data) => {
		setPageNo(data.selected + 1)
	}

	// const getInitials = (name) => {
	// 	const parts = name.split(' ');
	// 	const initials = parts.map(part => part.charAt(0).toUpperCase()).join('');
	// 	return initials;
	// };

	const calculateAge = (dateOfBirth) => {
		const dob = new Date(dateOfBirth);
		const today = new Date();
		let age = today.getFullYear() - dob.getFullYear();
		const monthDifference = today.getMonth() - dob.getMonth();
		if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < dob.getDate())) {
			age--;
		}
		return age;
	};

	const handleSearch = e => {
		const value = e.target.value.trim();
		if (value.trim() == "") {
			setListPatients(originaList)
		} else {
			const allResults = listPatients.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
			setListPatients(allResults)
		}
		
	}

	const fetchListPatients = async (pageNo = 1) => {
		let url = `patients`

		let data = {
			pageNo: pageNo,
			limit: limit
		}

		try {
			const response = await apiClient.post(url, data);

			if (response.status === 200) {
				setIsLoading(false)
				setListPatients(response.data.patients.data)
				setOriginalList(response.data.patients.data)
				setTotalCount(response.data.totalCount)
			} else {
				setIsLoading(false)
				setListPatients([])
				setOriginalList([])
				setTotalCount(0)
			}
			
		} catch (error) {
			setIsLoading(false);
			setError("An Error Occurred");
		}
		
	}

	useEffect(() => {
		const fetchData = async () => {
			await fetchListPatients(pageNo);
		};
		fetchData();
	}, [pageNo, limit]);
	
	// this is used to open the delete modal for confirmation
	useEffect(() => {
		if (openModal && modalRef.current) {
			const modal = new window.bootstrap.Modal(modalRef.current);
			modal.show();
			return () => modal.hide();
		}
		
	}, [openModal, patient]);
	

	
	return <>
		
		<div class="nk-block-head nk-block-head-sm">
			<div class="nk-block-between">
				<div class="nk-block-head-content">
					<h3 class="nk-block-title page-title"> Patients</h3>
					
					<div class="nk-block-des text-soft"><p>You have total {totalCount} patients in hospital.</p></div>
					{openModal && <p>{patient.name}</p> }
				</div>
				<p className="fw-bold fs-10 text-danger">{successMessage ? successMessage : "	"}</p>
				<div class="nk-block-head-content">
					<a href="/demo7/hospital/patient-add.html" class="btn btn-icon btn-primary d-md-none"><em class="icon ni ni-plus"></em></a>
					<NavLink to="/patients/add-patient" class="btn btn-primary d-none d-md-inline-flex"><em class="icon ni ni-plus"></em><span>Add Patient</span></NavLink>
				</div>
			</div>
		</div>
		<div class="nk-block">
			<div class="card card-bordered card-stretch">
				<div class="card-inner-group">
					<div class="card-inner position-relative card-tools-toggle">
						<div class="card-title-group">
							<div class="card-tools">
								<div class="form-inline flex-nowrap gx-3 gap-3">
									<input type="search" placeholder="Search Patient " onChange={(e) => handleSearch(e)} className="form-control" style={{ width: '280px' }} />
									{/* <button class="btn btn-lg btn-primary btn-block">Search</button> */}
								</div>
							</div>
							<div class="card-tools me-n1">
								<ul class="btn-toolbar gx-1">
								
									<li class="btn-toolbar-sep"></li>
									<li>
										<div class="toggle-wrap">
											<a href="#" class="btn btn-icon btn-trigger toggle" data-target="cardTools"><em class="icon ni ni-menu-right"></em></a>
											<div class="toggle-content" data-content="cardTools">
												<ul class="btn-toolbar gx-1">
													
													<li>
														<div class="dropdown">
															<a href="#" class="btn btn-trigger btn-icon dropdown-toggle" data-bs-toggle="dropdown"><em class="icon ni ni-setting"></em></a>
															<div class="dropdown-menu dropdown-menu-xs dropdown-menu-end">
																<ul class="link-check">
																	<li><span>Show</span></li>
																	<li className={limit === 10 ? 'active' : ''}><a onClick={(e) => handleSetLimit(10)}>10</a></li>
																	<li className={limit === 15 ? 'active' : ''}><a onClick={(e) => handleSetLimit(15)}>15</a></li>
																	<li className={limit === 20 ? 'active' : ''}><a onClick={(e) => handleSetLimit(20)}>20</a></li>
																</ul>
															
															</div>
														</div>
													</li>
												</ul>
											</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
						<div class="card-search search-wrap" data-search="search">
							<div class="card-body">
								<div class="search-content">
									<a href="#" class="search-back btn btn-icon toggle-search" data-target="search"><em class="icon ni ni-arrow-left"></em></a>
									<input type="text" class="form-control border-transparent form-focus-none" placeholder="Search by name or patient id" /><button class="search-submit btn btn-icon"><em class="icon ni ni-search"></em></button>
								</div>
							</div>
						</div>
					</div>
					<div class="card-inner p-0 table-responsive">
						<div className="text-center">
							
							
							<div className="mb-3">{!isLoading && !error &&  ``}</div>
							<div>{error !== "" && <p>No Patients</p>}</div>
						</div>
						{listPatients && <>
							<div class="nk-tb-list nk-tb-ulist">
								<div class="nk-tb-item nk-tb-head">
								
									<div class="nk-tb-col"><span class="sub-text fw-bold">Patient</span></div>
									<div class="nk-tb-col tb-col-sm"><span class="sub-text fw-bold">ID</span></div>
									<div class="nk-tb-col tb-col-xxl"><span class="sub-text fw-bold">Phone</span></div>
									<div class="nk-tb-col tb-col-lg"><span class="sub-text fw-bold">Gender</span></div>
									<div class="nk-tb-col tb-col-md"><span class="sub-text fw-bold">Date of Birth</span></div>
									<div class="nk-tb-col tb-col-xxl"><span class="sub-text fw-bold">Age</span></div>
								
								
									<div class="nk-tb-col nk-tb-col-tools">
										<ul class="nk-tb-actions gx-1 my-n1">
											<div class="nk-tb-col tb-col-xxl"><span class="sub-text fw-bold">Actions</span></div>
									
										</ul>
									</div>
								</div>

								{/* debug */}

								{listPatients.map((patient) => {
									return <div class="nk-tb-item" key={patient.id}>
								
										<div class="nk-tb-col">
											<NavLink to={`/patients/${patient.id}`}>
												<div class="user-card">
													<div class="user-avatar bg-primary"><span>{patient.initials}</span></div>
													<div class="user-info"><span class="tb-lead">{patient.name}</span>
														<span>{patient.email}</span>
													</div>
												</div>
											</NavLink>
										</div>
										<div class="nk-tb-col tb-col-sm">
											<span class="fw-medium">{patient.national_id}</span>
										</div>
										<div class="nk-tb-col tb-col-xxl"><span>{patient.phone}</span></div>
										<div class="nk-tb-col tb-col-lg"><span>{patient.gender}</span></div>
									
										<div class="nk-tb-col tb-col-md"><span class="tb-lead">{patient.dob}</span></div>
										<div class="nk-tb-col tb-col-xxl"><span>{patient.age}</span></div>
									
										<div class="nk-tb-col nk-tb-col-tools">
											<ul class="nk-tb-actions gx-1">
											
												<li>
													<div class="drodown">
														<a href="#" class="dropdown-toggle btn btn-icon btn-trigger" data-bs-toggle="dropdown"><em class="icon ni ni-more-h"></em></a>
														<div class="dropdown-menu dropdown-menu-end">
															<ul class="link-list-opt no-bdr" style={{ cursor: 'pointer' }}>
																<li>
																	<NavLink to={`/patients/${patient.id}`} >
																		<em class="icon ni ni-eye"></em><span>View Details</span>
																	</NavLink>
																</li>
																<li>
																	<NavLink to={`/patients/edit/${patient.id}`}><em class="icon ni ni-edit"></em><span>Edit</span></NavLink>
																</li>
																<li>
																	<a onClick={() => deleteModaHandler(patient)}><em class="icon ni ni-trash" ></em><span>Delete </span>
																	</a>
																</li>
															
															</ul>
														</div>
													</div>
												</li>
											</ul>
										</div>
									</div>
								})}
								{/* end debug */}
							</div>
						</> }
					</div>
					<div className="mb-3 text-center"> {isLoading && <p>Loading...</p>}</div>
					<div className="mt-3">
						{totalCount > limit &&
							<div className="mt-3 mb-2">
								{/* <CustomPagination  totalCount={totalCount} limit={limit} handlePageClick={handlePageClick} /> */}
								<ReactPaginate
									previousLabel={"previous"}
									nextLabel={"next"}
									breakLabel={"..."}
									pageCount={(totalCount / limit)}
									marginPagesDisplayed={3}
									pageRangeDisplayed={3}
									onPageChange={handlePageClick}
									containerClassName={"pagination justify-content-center"}
									pageClassName={"page-item"}
									pageLinkClassName={"page-link"}
									previousClassName={"page-item"}
									previousLinkClassName={"page-link"}
									nextClassName={"page-item"}
									nextLinkClassName={"page-link"}
									breakClassName={"page-item"}
									breakLinkClassName={"page-link"}
									activeClassName={"active"}
								/> 
							</div>
						}
					</div>
				</div>
			</div>



			
			<div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={modalRef}>
				<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
					<h5 className="modal-title" id="exampleModalLabel">Confirm Delete</h5>
					<button type="button" className="btn-close" aria-label="Close" onClick={closeModal}></button>
					</div>
					<div className="modal-body">
					<p><strong>Are you sure You want to delete : {patient.name}</strong></p>
					</div>
					<div className="modal-footer">
					<button type="button" className="btn btn-secondary" onClick={closeModal}>Cancel</button>
					<button type="button" className="btn btn-danger"  onClick={()=>removePatientHandler(patient.id)} >Confirm Delete</button>
					</div>
				</div>
				</div>
			</div>
			


		</div>


		

    </>
}


export default ListPatients;