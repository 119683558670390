import { useEffect, useState,useRef } from "react";
import Button from "../reusables/Button";
import apiClient from "../utils/axios";


import Select from 'react-select';
import { icdcodes } from './../constants/icdcodes';





const DiagnosisForm = ({ diagnosisId }) => {
    
    // console.log("ids ", diagnosis)
    
    const [id,setId] = useState(0);
    const [notes, setNotes] = useState("")
    const [medicalHist, setMedicalHist] = useState("")
    const [famMedHist, setFamMedHist] = useState("")
    const [icdCodes, setIcdCodes] = useState([])
    const [previousIcdCodes ,setPreviousIcdCodes] = useState([])
    const [diagnosisCodes, setDiagnosisCodes] = useState([])
    const [selectedIcdCodes,setSelectedIcdCodes] = useState([])
    const [selectedIds ,setSelectedIds] = useState([])
    const [previousDiagnosis ,setPreviousDiagnosis] = useState([])
    const [addDiagnosis,setAddDiagnosis] = useState(false)
    const [customDiagnosis,setCustomDiagnosis] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [errors,setErrors] = useState(null)
    const [formError , setFormError] = useState(null)
    const [successDiagnosisMessage,setSuccessDiagnosisMessage] = useState("")


    const fetchConsultationData = async () => {
        // console.log("data loaded",diagnosisId)
        try {
            let url = 'consultation/get-consultation-data';
            const response = await apiClient.post(url, { consultation_id: diagnosisId })
            
            if (response.data.status) {
                let data = response.data.data;
                setId(data.id ?  data.id : null )
                setNotes(data.doctors_notes ?  data.doctors_notes : "")
                setMedicalHist(data.past_medical_history ? data.past_medical_history : "")
                setFamMedHist(data.family_medical_history ? data.family_medical_history : "")  
               

                //this line give tback the selected diagonosis
                const addedDiagonis = []
                data.diagnosis_ids && 
                data.diagnosis_ids.length > 0 && 
                data.diagnosis_ids.forEach(element => {
                    const selectedD =  diagnosisCodes.find(d => d.id == element)
                    if(selectedD){
                        addedDiagonis.push(selectedD)
                    }
                });
                setPreviousDiagnosis(addedDiagonis)

                // load the icd codes seleted back

                let icd10Data = data.icd ? JSON.parse(data.icd) : []
                setPreviousIcdCodes(icd10Data)


                // const addedIcdCodes = []
                // data.icd.length > 0 && 
                // data.icd.forEach(element => {
                //     const selectedD =  diagnosisCodes.find(d => d.id === element)
                //     if(selectedD){
                //         addedIcdCodes.push(selectedD)
                //     }
                // });
                // setPreviousIcdCodes(addedIcdCodes)


                // console.log("final data", data.icd)
            }
        } catch (error) {
            
        }
    }

    const handleSubmitDiagnosis = async (e) =>{
        e.preventDefault()
        let url = "consultation/update"
        // alert("ok")

        if(notes.trim() === "" || medicalHist.trim() === "" ||  famMedHist.trim() === ""){
            setFormError("Please feed Diagnosis Information")
            setTimeout(() => {
                setFormError(null)
            }, 3000);
            
            return ;
        }
        // if(selectedIds.length ===  0) {
        //     setFormError("Please Select a dignosis first")
        //     setTimeout(() => {
        //         setFormError(null)
        //     }, 3000);
        //     return; 
        // }
        //update data selected
        
        let diagnosisData = { 
            id:id,
            diagnosis_ids: selectedIds,
            icd:selectedIcdCodes,
            doctors_notes:notes,
            past_medical_history:medicalHist,
            family_medical_history: famMedHist
        }
        console.log("data to save",diagnosisData)
        try {
            const response = await apiClient.post(url,diagnosisData)
            if(response.status === 200 ){
                setSuccessMessage(response.data.message)
                fetchConsultationData()
                setTimeout(() => {
                    setSuccessMessage("") 
                }, 3000); 
            }else{
                console.log("response failed message",)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const options = diagnosisCodes &&  diagnosisCodes.length > 0 ? diagnosisCodes.map(diagnosis => ({
        value: diagnosis.id,
        label: diagnosis.name
    })) : [];
    
    const icdCodesOptions = icdCodes.length > 0 ? icdCodes.map(icdCode => ({
        value: icdCode.code,
        label:icdCode.name
     })): []


    // const handlelAddDiagnosis = async () =>{
    //     if(customDiagnosis.trim() === "") {
    //             setErrors("Plese add a diagnosis")
    //             setTimeout(() => {
    //                 setErrors(null) 
    //         }, 3000); 
    //         return ;
    //     }
    //     try {
    //         const response = await apiClient.post("diagnosis/save-diagnosis",{customDiagnosis})
    //         if(response.status === 200 ){
                
    //             setSuccessDiagnosisMessage("Added Successfully")
    //             setTimeout(() => {
    //                 setSuccessDiagnosisMessage("")
    //                 setCustomDiagnosis("")
    //                 setAddDiagnosis(false)
    //                 fetchDiagnosisCodesData()
    //             }, 1000);
                
    //         }else{
    //             console.log("an error occured.", response)
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const fetchDiagnosisCodesData = async () =>{
        let url = 'diagnosis/diagnosis-codes'  
        const response = await apiClient.post(url)
        if (response.data.status) {
            let codes = response.data.data 
            // console.log("codes",codes)
            setDiagnosisCodes(codes)
        } else {
             console.log("something happened")
        }
        // 
    }
   

    const closeDiagnosisInput = () =>{
        setCustomDiagnosis("")
        setAddDiagnosis(false)
    }

    const handleDiagnosisChange = (selectedDiagnosisOptions) => {
        if (selectedDiagnosisOptions && selectedDiagnosisOptions.length > 0) {
            const selectedIds = selectedDiagnosisOptions.map(option => parseInt(option.value));
            setSelectedIds(selectedIds); // Update the selected IDs state
            console.log("ids",selectedIds)
        } else {
            setSelectedIds([]); // Handle empty or undefined case
        }
    };

    const handleIcdCodeChange = (selectedIcdCodeOption) => {
        if (selectedIcdCodeOption && selectedIcdCodeOption.length > 0) {
             console.log(selectedIcdCodeOption)
            const selectedIds = selectedIcdCodeOption.map(option => ({
                                        name: option.label, 
                                        code: option.value  
                                    }));
            setSelectedIcdCodes(selectedIds); // Update the selected IDs state
            // console.log("icds-codes",selectedIds)
        } else {
            setSelectedIcdCodes([]); // Handle empty or undefined case
        }
    }


     useEffect(() => {
        const loadData = async () =>{
            await fetchDiagnosisCodesData()   
        }
        loadData()
    },[])

    useEffect(()=>{
        if (diagnosisCodes.length > 0) {
            const loadConsultationData = async () => {
                await fetchConsultationData();
            };
            loadConsultationData();
        }
    },[diagnosisCodes])

    
    useEffect(() => {
        //fetch fetchIcds code
        // console.log("icd10 codes",icdCodes)
       setIcdCodes(icdcodes)
    },[])
   


    return (
        <div className="nk-block">
            <div className="nk-block nk-block-between">

                {/* <div className="mb-3">
                    <h8 className="title"><b>Reasons for Visit</b></h8>
                    <p>{diagnosis.reason_for_visit}</p>
                
                    <h8 className="title"><b>Current Medications</b></h8>
                    <p>{diagnosis.current_medications}</p>
                    <hr className="" style={{ border: '1px solid black'}}/>
                </div> */}

                
                

                <div className="nk-block-head">
                    <h6 className="title">Diagnosis</h6>
                </div>           
                
            </div>

            <div className="row gy-4">
               <div className="col-md-8 col-sm-12 col-xxl-8">
               <div className="col-xxl-12 col-md-12">
                    <div className="form-group">
                        <label className="form-label" for="full-name">Notes</label>
                        <div className="form-control-wrap">
                            <textarea type="text" className="form-control" id="full-name" placeholder="Notes"
                            value={notes}
                            onChange={(e)=> setNotes(e.target.value)} 
                            />
                        </div>
                    </div>
                </div>
                <div className="col-xxl-12 col-md-12">
                    <div className="form-group">
                        <label className="form-label">Medical History</label>
                        <div className="form-control-wrap">
                            <div className="form-icon form-icon-right">
                                <em className="icon ni ni-calendar"></em>
                            </div>
                            <textarea type="text" className="form-control"
                            value={medicalHist}
                            onChange={(e)=> setMedicalHist(e.target.value)}   
                            placeholder="Medical History" />
                        </div>
                    </div>
                </div>

                <div className="col-xxl-12 col-md-12">
                    <div className="form-group">
                        <label className="form-label">Family Medical History</label>
                        <div className="form-control-wrap">
                            <div className="form-icon form-icon-right">
                                <em className="icon ni ni-calendar"></em>
                            </div>
                            <textarea type="text" className="form-control"
                            value={famMedHist}
                            onChange={(e)=> setFamMedHist(e.target.value)} 
                             />
                        </div>
                    </div>
                    
                </div>
               </div>

               <div className="col-md-4 col-sm-4 col-xxl-4">
                    <div className="col-xxl-12 col-md-12 mt-2"> 
                        <div class="form-group">
                            <label className="form-label">Select Diagnosis</label>
                                <div class="form-control-wrap">
                                <Select 
                                    options={options} 
                                    onChange={handleDiagnosisChange} 
                                    placeholder="Search Diagnosis..." 
                                    isMulti 
                                />
                            </div>
                        </div>
                       
                    </div>
                    <ul className="mt-2">
                        <h7><strong> Diagnosis Selected</strong></h7>
                        {previousDiagnosis && previousDiagnosis.map(diagnosis=><div>-{diagnosis.name}</div>)}                
                    </ul>

                    <div className="col-xxl-12 col-md-12 mt-2"> 
                        <div class="form-group">
                            <label className="form-label">Select ICD-10</label>
                                <div class="form-control-wrap">
                                <Select 
                                    options={icdCodesOptions} 
                                    onChange={handleIcdCodeChange} 
                                    placeholder="Search Code..." 
                                    isMulti 
                                />
                            </div>
                        </div>
                    </div>
                    <ul className="mt-2">
                        <h7><strong>ICD-10 Selected</strong></h7>
                        {previousIcdCodes.length >  0  && previousIcdCodes.map(icd=><div>- {icd.name}</div>)}                
                    </ul>

               </div>
              
               <div className="col-md-3 mx-auto">
                    <button className="btn btn-primary" onClick={handleSubmitDiagnosis}>Submit</button>
                    <p className="text-danger text-center">{formError && formError}</p>
                    <p className="text-success fw-bold mt-3 text-center">{successMessage &&  successMessage }</p>
               </div>
              

               
               
            </div>
        </div>

    );
}

export default DiagnosisForm;