export const bloodgroups = [
    {
        "group":"A+",
        "value":"A+"
    },
    {
        "group":"A-",
        "value":"A-"
    },
    {
        "group":"AB+",
        "value":"AB+"
    },
    {
        "group":"AB-",
        "value":"AB-"
    },
    {
        "group":"B+",
        "value":"B+"
    },
    {
        "group":"B-",
        "value":"B-"
    },
    {
        "group":"O+",
        "value":"O+"
    },
    {
        "group":"O-",
        "value":"O-"
    },
    
]



